@import "~bootstrap/scss/bootstrap";

@import "layout";

@import "common";

@import "./components/header";
@import "./components/now";
@import "./components/wts";
@import "./components/scheduleHeader";
@import "./components/customTable";
@import "./components/sidebar";
@import "./components/modal";
@import "./components/calenderHeader";

@import "./pages/home";
@import "./pages/admin";
@import "./pages/landing";
@import "./pages/schedule";
@import "./pages/getStarted";
@import "./pages/personalInfo";
@import "./pages/finalStep";
@import "./pages/appointment";
@import "./pages/healthInfo";
@import "./pages/patientWaitList";
@import "./pages/providerLanding";
@import "./pages/provider";
@import "./pages/reimbursment";
