.CalenderHeader {
  .dx-scheduler-header {
    border: none;
    background-color: #fff;
    padding: 0;
    margin: 0;
  }

  .calender-buttons {

    button:not(:last-of-type){
      margin-right: 10px;
    }
  }
}