.header {
  nav {
    box-shadow: #0000003d 0px 3px 8px;
    height: 74px;
  }

  .link-style {
    color: #bababa;
  }
}
