.search-control {
}

.search-control .search-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #37698b;
}

.search-control .form-control {
  padding-left: 2.375rem;
  width: 170px;
  color: #37698b;
  border-color: #37698b;
  border-width: 2px;
  border-radius: 10px;
}
