// Learn About Section
.learn-about-section {
  background: url("../../assets/images/5.png") center left no-repeat;
  background-size: cover;
  height: 500px;
  
  .thumbnail {
    height: 100%;
    width: 100%;
  }
  
  @media screen and (max-width: 600px) {
    height: 350px;
  }
}

// Testimonials
.testimonials-component {
  h3 {
    color: #ffc107;
  }

  .card {
    height: auto;
    margin: 0;
    border-color: lightgrey;
    align-items: center;
  }
}