.link-style {
  color: #bababa;
}

.formContainer {
  max-width: 500px;
  width: 100%;
  background-color: white;
}

.scheduleBtn {
  background-color: #02567d !important;
  width: 100px !important;
  border-radius: 5px !important;
  border: none !important;
}

.patientBtn {
  background-color: #02567d !important;
  width: 100px !important;
  border-radius: 5px !important;
  border: none !important;
}

.waitlistBtn {
  border-color: #02567d !important;
  color: #02567d !important;
  width: 100px !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.breadcrumb {
  padding: 3.75rem 1rem !important;
  justify-content: center !important;
  align-items: center !important;
}

.breadcrumb-item a {
  color: #02567d;
  text-decoration: none;
}

.headindDetails {
  margin-top: 50px;
  text-align: center;
}

.right {
  float: right !important;
}

.padding-15 {
  padding: 15px;
}

.font-weight-bold {
  font-weight: bold;
}

.color-grey {
  color: rgba(0, 0, 0, 0.5);
}

.custom-control.custom-switch,
.custom-div {
  width: 75% !important;
  margin: 25px auto !important;
  text-align: center !important;
}

.scheduleRow {
  margin-top: 5px;
  flex-direction: row;
}

.scheduleHeading {
  background-color: white;
  margin-top: 10px !important;
  border-top: 1px solid;
  border-top-color: lightgrey;
  height: auto;
}

.dx-tabs-wrapper {
  display: none !important;
}

.dx-button {
  border-style: none !important;
}

.dx-button:hover {
  border-style: none !important;
}

.dx-button-mode-contained {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-button-mode-contained:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-button-mode-contained:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-state-active {
  background-color: none !important;
}

.dx-scheduler-appointment-title {
  color: #000000 !important;
}

.dx-scheduler-appointment-horizontal {
  background-color: none !important;
}

.dx-scrollview-content,
.dx-item,
.dx-list-item,
.dx-list-item-content {
  width: 100px !important;
  color: #000000 !important;
}
