.personal-info {
  height: calc(100vh - 75px);
  width: 100%;
  background: #ffffff;
  padding: 40px;

  .personal-title {
    color: #1a547a;
  }
}
