.imgRadius {
  border-radius: 20px !important;
}
.customHeight {
  min-height: 100px !important;
}
.roundImage {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
}
.testimonialText {
  font-family: monospace;
}

.customModal {
  border: none !important;
  justify-content: center !important;
}

.modalData {
  justify-content: center !important;
  align-items: center !important;
}

.wts {
  .card {
    padding: 0;
    margin: 0;
    border: none;

    .card-body {
      padding: 1.25rem 0;
    }
  }
}
