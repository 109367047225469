.landing {
  text-align: center;

  button {
    width: 170px !important;
    border-color: #1a547a !important;
    border-radius: 10px !important;
    background-color: #1A547A !important;
    color: #ffffff !important;
  }
}
