.reimbursment {
  min-height: calc(100vh - 75px);
  width: 100%;
  background: #fff;
  padding: 40px;
  
  .dx-scheduler-work-space {
    display: none;
  }
  
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 0px);
  }
}
