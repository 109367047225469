.card {
  max-width: 500px;
  width: 100%;
  border-radius: 20px !important;
  margin: 0 20px;
  padding: 40px 20px;
}

@media screen and (max-width: 767px) {
  .hide-sm-down {
    display: none;
  }
}