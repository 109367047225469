.appointment-container {
  @media screen and (min-width: 701px) {
    margin-left: 200px;;
  }
}

.appointment {
  .scheduleRow {
    margin-top: 5px;
    flex-direction: row;
  }

  .scheduleHeading {
    background-color: white;
    margin-top: 10px !important;
    border-top: 1px solid;
    border-top-color: lightgrey;
    height: auto;
  }

  .dx-tabs-wrapper {
    display: none !important;
  }

  .dx-button {
    border-style: none !important;
  }

  .dx-button:hover {
    border-style: none !important;
  }

  .dx-button-mode-contained {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dx-button-mode-contained:active {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dx-button-mode-contained:focus {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dx-state-active {
    background-color: none !important;
  }

  .dx-scheduler-appointment-title {
    color: #000000 !important;
  }

  .dx-scheduler-appointment-horizontal {
    background-color: none !important;
  }

  .dx-scrollview-content,
  .dx-item,
  .dx-list-item,
  .dx-list-item-content {
    width: 100px !important;
    color: #000000 !important;
  }
}

.appointment-grid {
  width: 100%;
  height: inherit;
  background-color: #fff;
  padding: 12px 0px;
  border-color: #e2E2E2;
  border-style: solid none none solid;
  border-width: 1px 1px;

  .headerCol {
    text-align: left;
    font-size: 14px;
    color: #000;
    font-style: monospace;
  }
  
  .headerText {
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: bold;
    font-style: monospace;
  }
  
  .userRow {
    margin-left: 2px !important;
    height: 35px;
    align-items: center;
    margin-top: 10px;
  }
  .dataTable {
  }
  
  .userHeadingRow {
    margin-left: 5px !important;
  }
  
  .userRow:hover {
    border-radius: 5px;
    background-color: #02567D !important;
    /*margin-left: 2px;*/
  }
  
  .userRow:hover .headerCol {
    color: #fff !important;
  }
  
  .gridHeading {
    border-bottom: 1px solid #E2E2E2;
    height: 45px;
    /*align-items: center;*/
  }
  .activeHeaderCol {
    text-align: left;
    font-size: 14px;
    color: #46BD0C;
    font-style: monospace;
  }
  
  .timeCol {
    border-right: 1px solid #E2E2E2;
  }
  
  .userRow:nth-child(even) {background: #CCC; margin-left:2px; border-radius: 5px;}
  
  .searchbar {
    height: auto;
    border-bottom: 1px solid #E2E2E2 !important;
  }
  
  .calenderPicker {
    border: 1px solid #02567D;
  }
  
  .searchbar .container .dx-scheduler .dx-scheduler-work-space-odd-cells {
    display: none !important;
  }
  
  .dx-icon, .dx-button-text {
    color: #02567D !important;
    font-size: 16px !important;
  }
  
  .dx-scheduler-header  {
    border: none;
    background-color: white;
    padding: 0;
    margin: 0;
  }
  
  .searchField {
    border: 1px solid #02567D !important;
  }
  
  .walkInButton {
    float: right;
  }
}


@media screen and (max-width: 767px) {
  div.content.appointment-container {
    align-items: initial;
  }
  .appointment-grid {
    height: auto;
  }
}