.provider {
  width: 100%; 

  &-form-container {
    .provider-title {
      color: #1A547A;
    }

    .provider-description {
      color: #2E2E2E;
      font-size: 19px;
    }

    .provider-form {
      .provider-forgot {
        color: #1A547A;
        font-size: 13px;
      }
  
      .provider-submit {
        background-color: #1A547A;
        border-color: #1A547A;
        width: 145px;
        margin: 0 auto;
      }
    }
  }

  &-image-container {
    img {
      height: calc(100vh - 75px);
      object-fit: cover;
      object-position: center;
    }
  }

  @media screen and (max-width: 767px) {
    &-image-container {
      display: none;
    }
  }
}

.schedule-provider {
  @media screen and (min-width: 701px) {
    &-with-sidebar {
      margin-left: 200px;
    }
  }
}