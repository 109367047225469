.schedule-header {
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    color: #7B7B7B;

    img {
      margin-right: 10px;
    }

    p {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}
