.provider-landing {
  height: 100%;

  .landing-card {
    .landing-title {
      color: #1a547a;
    }

    .landing-description {
      color: #4e5b60;
      font-size: 19px;
    }

    .landing-checkbox {
      color: #4e5b60;
    }
  }
}