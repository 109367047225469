.n-caption {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-weight: bold;
}
.nowDesc {
  font-size: 14px !important;
}

.nowBtn {
  color: #0168e8 !important;
}

.imageOverlay {
  display: block;
}

.thumbnail {
  position: relative;
  display: inline-block;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-weight: bold;
}

.userContainer {
  min-height: 100px;
  border-color: lightgrey !important;
}

.roundImage {
  height: 120px !important;
  width: 120px !important;
  border-radius: 50% !important;
}


@media screen and (max-width: 767px) {
  .n-caption {
    width: 90%;
    left: 50%;
  }
}