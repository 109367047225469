/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 30px 14px;
  width: 200px;
  background-color: #fff;
  position: fixed;
  border-top-width: 1px;
  border-color: #a4a4a4;
  height: 100%;
  overflow: auto;
  font-size: 14px;
  z-index: 1;
}

/* Sidebar links */
.sidebar a {
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  color: #a4a4a4;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  background-color: #e2e2e2;
  border-radius: 10px;
  color: #174e6d;
  margin: 4px;
  text-decoration: none;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  background-color: #e2e2e2;
  border-radius: 10px;
  color: white;
  margin: 4px;
  text-decoration: none;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  height: calc(100vh - 75px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

/* On screens that are less than 767px wide, make the sidebar into a topbar */
@media screen and (max-width: 767px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;

    
    a {
      float: left;
    }

    .navbar-nav.sidebar-links {
      flex-direction: row !important;
    }
  }

  div.content {
    margin-left: 0;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.scheduleBox {
  height: 300px;
  width: 500px;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 5px;
}

.headindSchedule {
  color: #174e6d;
}

.scheduleBtn {
  background-color: #02567d !important;
  width: 100px !important;
  margin-left: 10px !important;
  border: none !important;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.sidebar {
  @media screen and (min-width: 576px) {
    h4 {
      display: none;
    }
  }
}