.get-started {
  &-form-container {
    background-image: url('../../assets/images/login-gradient.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  button {
    width: 120px !important;
    border-color: #c1c1c1 !important;
  }

  .get-started-title {
    color: #1a547a;
  }
  
  .get-started-description {
    color: #4e5b60;
    font-size: 19px;
  }
  
  .get-started-submit {
    background-color: #1a547a !important;
    border-color: #1a547a !important;
    width: 145px !important;
    margin: 0 auto !important;
  }

  &-image-container {
    img {
      height: calc(100vh - 75px);
      object-fit: cover;
      object-position: center;
    }
  }
  
  
  @media screen and (max-width: 767px) {
    &-image-container {
      display: none;
    }
  }
}
